import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Text, HeadingInfoContainer, SectionHeading as HeadingTitle, SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import AppStoreButton from "images/app-store-button.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex justify-center relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const FrameImage = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
  tw`w-1/2`,
]);



const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-2 font-bold text-left text-3xl sm:text-3xl lg:text-3xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-primary-100`;


const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-4 leading-loose`,
  props.buttonRounded && tw`rounded-full`
]);

const SecondaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0 p-0 bg-transparent hover:bg-transparent`,
  props.buttonRounded && tw`rounded-full`,
  props.id
]);

const Actions = tw.div`flex flex-col sm:flex-row justify-center lg:justify-start`;



export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  primaryButtonText = "",
  primaryButtonUrl = false,
  secondaryButtonText = "",
  secondaryButtonUrl = false,
  secondaryButtonId = null,
  imageSrc = null,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = true
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <FrameImage css={imageCss} src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            
              <Actions>

              {secondaryButtonUrl &&  <SecondaryButton id={secondaryButtonId} buttonRounded={buttonRounded} href={secondaryButtonUrl} as="a"><Image src={AppStoreButton}></Image>
              {secondaryButtonText}
            </SecondaryButton> }
  
              {primaryButtonUrl &&  <PrimaryButton buttonRounded={buttonRounded} href={primaryButtonUrl} as="a">
              {primaryButtonText}
            </PrimaryButton> }
             
              </Actions>
                
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
