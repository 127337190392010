import React, { useRef }  from "react";

import { ToastContainer, toast } from "react-toastify";
 import "react-toastify/dist/ReactToastify.css";

import styled from "styled-components";
import tw from "twin.macro";
import emailjs from '@emailjs/browser';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100  focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6 `;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-white text-primary-500 rounded font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline  hocus:-translate-y-px hocus:shadow-xl`;


export default () => {

  const form = useRef();


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_mm6dmeo', 'template_26dev3u', form.current, {
        publicKey: 'Bh8W3GsH5jzk4p2p1',
      })
      .then(
        (result) => {
          console.log(result.text)
          console.log('SUCCESS!')
          e.target.reset()
          toast.success("Message successfully sent", {
          });

        },
        (error) => {
          console.log('FAILED...', error.text);
          toast.success("Something went wrong", {
          });
        },
      );
  };


  return (
    <Container>
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            <h2>Contact Us</h2>
            <form action="#" ref={form} onSubmit={sendEmail}>
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Your Name</Label>
                    <Input type="text" name="from_name" placeholder="Richard Hendricks" />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Your Email Address</Label>
                    <Input type="email" name="from_email" placeholder="richard.hendricks@piedpiper.com" />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="name-input">Your Message</Label>
                    <TextArea name="message" placeholder="Let's collaborate!"/>
                  </InputContainer>
                </Column>
              </TwoColumn>

              <SubmitButton type="submit" value="Submit">Submit</SubmitButton>
              <ToastContainer></ToastContainer>
            </form>
          </div>
        </FormContainer>
      </Content>
    </Container>
  );
};
