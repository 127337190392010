import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";

import MainFeature1 from "components/features/TwoColWithButton.js";
import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";

import Features from "components/features/ThreeColSimple.js";

import DownloadIcon from "images/download-icon.svg";
import StarIcon from "images/star-icon.svg";
import HeartIcon from "images/heart-icon.svg";
import HeaderImage from "images/header.svg";
import SimpleContactUs from "components/forms/SimpleContactUs";


import HappusImages from "images/happus-app-screenshots.png";
import TimusImages from "images/timus-app-screenshots.png";
import VisusImages from "images/visus-app-screenshots.png";

import { ContentWithVerticalPadding } from "components/misc/Layouts";


const SectionContainer = tw(ContentWithVerticalPadding)``;

const Subheading = tw.span`uppercase tracking-wider text-sm `;


export default () => {
  return (
    <AnimationRevealPage>
      <Header />     
      <MainFeature3
        subheading={false}
        heading="We create apps for happier and healthier life"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl="#contact-form"
        imageSrc= {HeaderImage}
        description={false}
        textOnLeft={true}
      />      
    
      <Features
        subheading={false}
        heading="Our apps"
        linkText = ""
        cards={[
          {
            imageSrc: DownloadIcon,
            title: "50K+ downloads",
            description: "Thousands of users worldwide download our apps yearly"
            
            
          },
          {
            imageSrc: StarIcon,
            title: "4.8 stars",
            description: "The quality, detail, and positive impact of our apps is a priority"
          },
          {
            imageSrc: HeartIcon,
            title: "1K+ ratings",
            description: "Every rating and review matters and is used to improve the apps"
          },
          
        ]
  
      }
      />
      <MainFeature1
        subheading={<Subheading>Happus</Subheading>}
        heading="Have a happy and healthy relationship"
        description="Happus is your guide for building happy and healthy relationship and even improving your marriage. Relationship tracker, couple quizzes, conversation starters, facts, tips, games and even date ideas — everything to make you a happier couple"
        buttonRounded={false}
        secondaryButtonUrl="https://apps.apple.com/app/id1669465737"
        secondaryButtonId = 'main_appstore_happusapp_button'
        primaryButtonText="Open Press Kit"
        primaryButtonUrl="/press-kit/happus"
        imageSrc={HappusImages}
        textOnLeft={false}
      />
      <MainFeature1
        subheading={<Subheading>Timus</Subheading>}
        heading="Manage your time in a better way"
        description="Timus is an universal visual multi timer that helps you make everything on time. Choose any template that you want – tabata, 7-minute interval workout, pomodoro, egg timer, pasta timer – or create a custom timer yourself."
        buttonRounded={false}
        secondaryButtonUrl="https://apps.apple.com/app/id6461119151"
        secondaryButtonId = 'main_appstore_timusapp_button'
        primaryButtonText="Open Press Kit"
        primaryButtonUrl="/press-kit/timus"
        imageSrc={TimusImages}
        textOnLeft={true}
      />
      <MainFeature1
        subheading={<Subheading>Visus</Subheading>}
        heading="Visualize and achieve your goals"
        description="Visus is a powerful vision board maker that helps you achieve your dreams. Visualize your ideal life, stay motivated, and make your goals a reality. Start living the life you've always imagined today"
        buttonRounded={false}
        secondaryButtonUrl="https://apps.apple.com/app/id6473835640"
        secondaryButtonId = 'main_appstore_visusapp_button'
        primaryButtonText="Open Press Kit"
        primaryButtonUrl="/press-kit/visus"
        imageSrc={VisusImages}
        textOnLeft={false}
      />
      <SectionContainer id="contact-form">
            
      <SimpleContactUs></SimpleContactUs>
      </SectionContainer>
      <Footer />
    </AnimationRevealPage>
  );
};
