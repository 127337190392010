import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Text, HeadingInfoContainer, HeadingDescription, SectionHeading as HeadingTitle  } from "../misc/Headings.js";
import { Container, SingleColumn } from "../misc/Layouts.js";

import { ReactComponent as InstagramIcon } from "images/instagram-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as TikTokIcon } from "images/tiktok-icon.svg";


import HappusLogo from "images/happus-logo.png";

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80  bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const DetailsFull = tw.div`md:w-1/2 mt-4 md:mt-0 mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;



export default ({
  app = "Happus",
   hasLinks = true,
   instagramLink = "https://www.instagram.com/happus.co/",
   twitterLink = "https://twitter.com/happus_app",
   tiktokLink = "https://www.tiktok.com/@happusapp",
   cards = [
    {
      imageSrc:
        HappusLogo,
      title: "What is it?",
      description:
      <Text>
        <p>
        Happus is an app for building happy and healthy relationship. It offers a science-based approach for improving couples relationships
        </p>
      </Text>,
    },

    {
      imageSrc: null,       
      title: "Key Features",
      description:
      <Text>
        <ul>
        <li>Count the days since you started dating</li>
        <li>Track your relationship satisfaction and get tips to improve it</li>
        <li>Complete science-backed check-ins to spot any issues</li>
        <li>Get detailed quality time and date ideas</li>
        <li>Get research-based discussion topics and take quizzes</li>
        <li>Play relationship versions of games Bingo and Would You Rather</li>
        <li>Track relationship health with dashboards and gain insights</li>
      </ul>
      </Text>,
    },

    {
      imageSrc: null,
      title: "Who is it for?",
      description:
      <Text>
      <p>
      Happus is for couples who want to make their relationship a priority. Even when it was a busy day at work or even a busy month and you don’t really have any energy to come up with any discussion topics or quality time ideas.
      </p>
      <p>
      Whether you're a new couple, in a long-term relationship, or married, Happus will make your relationships deeper and more exciting.
    </p>
    </Text>,
    },
    {
      imageSrc: null,
      title: "Testimonials",
      description:
      <Text>
      <p>
      «Definitely recommend! What a neat app for couples! My husband and I love the fact that it has different creative activities for us to do together and it gives us an opportunity to look at our relationship in a different way. Every couple needs to download Happus!»
      </p>
      <p>
      Brittany, USA
    </p>
    </Text>,
    },
    {
      imageSrc: null,
      title: "Pricing",
      description:
      <Text>
      <p>
      Happus is free to download. You can upgrade to Happus Premium for $6.99/month, $44.99/year to gain access to some content
      </p>
    </Text>,
    }
  ]
}) => {
   

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Press Kit</HeadingTitle>
          <HeadingDescription>
            {app}
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
             {card.imageSrc != null && <Image imageSrc={card.imageSrc} />} 
             {card.imageSrc == null ?
              <DetailsFull>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
              </DetailsFull> :  <Details>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
               {hasLinks && <SocialLinksContainer>
             {instagramLink != null && <SocialLink href={instagramLink}>  <InstagramIcon />
              </SocialLink>} 
              {twitterLink != null && <SocialLink href={twitterLink}>  <TwitterIcon />
              </SocialLink>} 
              {tiktokLink != null && <SocialLink href={tiktokLink}>  <TikTokIcon />
              </SocialLink>} 
            </SocialLinksContainer>} 
              </Details>}
            </Card>
          ))}
        </Content>
      </SingleColumn>
    </Container>
  );
};
