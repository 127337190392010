import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

import {  SingleColumn } from "../misc/Layouts.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";


const Container = tw.div`relative`;
const Content = tw.div`mt-16`;

const Header = tw.h4`text-3xl font-bold text-gray-900`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto  leading-loose`,
  props.buttonRounded && tw`rounded-full`
]);


export default ({
  heading = "Promo Video",
  primaryButtonText="Download",
  watchVideoYoutubeUrl="",
}) => {

  return (
    <>
      <Container>
        <SingleColumn>
          
            <Header>
              {heading}
            </Header>

          <Content>
          <div className="content">
            <ResponsiveVideoEmbed url={watchVideoYoutubeUrl} tw="w-full" />
          </div>

          <PrimaryButton href={watchVideoYoutubeUrl} as="a" target="_blank" rel="noopener noreferrer" download> 
              {primaryButtonText}
            </PrimaryButton>  
          </Content>
                
        </SingleColumn>
      </Container>
      
    </>
  );
};
