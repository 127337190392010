import tw from "twin.macro";
import styled from "styled-components";


export const Title = tw.h1`text-5xl sm:text-5xl lg:text-6xl font-black tracking-wide text-center text-primary-500`
export const SectionHeading = tw.h2`text-4xl sm:text-4xl lg:text-5xl font-black tracking-wide text-center text-primary-500`
export const Subheading = tw.h5`font-bold text-primary-500`

export const Text = styled.div`
  ${tw`text-lg  text-primary-300`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-4xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-3xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-2xl font-bold mt-6`}
  }
  h4 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  ol {
    ${tw`list-upperalpha list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export const HeadingInfoContainer = tw.div`flex flex-col items-center`;
export const HeadingDescription = tw.p`mt-4 font-medium text-primary-300 text-center max-w-sm`;
