import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
// import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import { SectionHeading, Text } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;

export default ({ headingText = "Terms of Service" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <SectionHeading>{headingText}</SectionHeading>
          </HeadingRow>
          <Text>

          {/* <h2>Terms of Service</h2> */}
                <p>Effective Date: February 1, 2023</p>
                <h3>1. Introduction</h3>
                <p>Welcome to HAPPUS, LLC (“<b>Company</b>”, “<b>we</b>”, “<b>our</b>”, “<b>us</b>”).</p>
                <p>These Terms of Service (“<b>Terms</b>”, “<b>Terms of Service</b>”) govern your use of our web pages located at <a href="https://www.happus.co">https://www.happus.co</a> and our mobile applications (together or individually, “<b>Services</b>”) operated by HAPPUS, LLC.</p>
                <p>Our Privacy Policy also governs your use of our Services and explains how we collect, safeguard, and disclose information that results from your use of our Services. It is available at: <a href="https://www.happus.co/privacy-policy.html">https://www.happus.co/privacy-policy</a>.</p>
                <p>Your agreement with us includes these Terms and our Privacy Policy (“<b>Agreements</b>”). You acknowledge that you have read and understood the Agreements, and agree to be bound by them.</p>
                <p>If you do not agree with (or cannot comply with) the Agreements, then you may not use the Services. These Terms apply to all visitors, users, and others who wish to access or use our Services.</p>
                <h3>2. Communications</h3>
                <p>By creating an Account on our Services, you agree to subscribe to newsletters, marketing or promotional materials, and other information we may send. However, you may opt out of receiving any or all of these communications from us by following the unsubscribe link.</p>
                <h3>3. Purchases</h3>
                <p>If you wish to purchase any product or service made available through our Services (“<b>Purchase</b>”), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.
                You represent and warrant that: 
                <ol tpe="A"><li>you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that</li> <li>the information you supply to us is true, correct and complete.</li></ol></p>
                <p>We may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties, subject to our Privacy Policy.</p>
                <p>We reserve the right to refuse or cancel your order at any time for reasons including, but not limited to: product or service availability, errors in the description or price of the product or service, error in your order, or other reasons.</p>
                <p>We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.</p>
                <p>If you do not agree with (or cannot comply with) Agreements, then you may not use the Services. These Terms apply to all visitors, users, and others who wish to access or use our Services.</p>
                <h3>4. Payment Obligations</h3>
                <p>If you purchase our Services, fees are specified during check-out and must be paid in advance. Payment obligations are non-cancelable and fees paid are non-refundable. For example, if you cancel your subscription, you are responsible for any unpaid fees from the subscription, and Services under the subscription will be deemed fully performed and delivered upon expiration of the subscription term.</p>
                <p>Fees are stated exclusive of any taxes, levies, duties, or similar governmental assessments of any nature, including, for example, value-added, sales, use, or withholding taxes assessable by any jurisdiction (“<b>Taxes</b>”). You are responsible for paying all Taxes associated with your subscription, except for those based on our net income.
                A subscription has a term that may expire or be terminated. All subscriptions automatically renew for additional periods equal to one year or the preceding term, whichever is shorter, and the per-unit pricing during any automatic renewal term will remain the same as it was during the immediately prior term. Either party can give the other a notice of non-renewal before the end of a subscription term to stop the subscription from automatically renewing.</p>
                <p>Any credits that you accrue to your account will expire following the expiration or termination of the subscription, will have no currency or exchange value, and will not be transferable or refundable.</p>
                <p>If any fees owed to us by you (excluding amounts disputed reasonably and in good faith) are thirty days or more overdue, we may, without limiting our other rights and remedies, terminate your subscription until those amounts are paid in full. You acknowledge and agree that the loss of a subscription will result in a decrease in certain features and functionality, as well as a potential loss of access to data.</p>
                <h3>5. Content</h3>
                <p>Content found on our Services is the property of HAPPUS, LLC or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without expressing prior written permission from us.</p>
                <h3>6. Prohibited Uses</h3>
                <p>You may use our Services only for lawful purposes and in accordance with our Terms. You agree not to use our Services:
                <ol type="A"><li>In any way that violates any applicable national or international law or regulation.</li>
                <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</li>
                <li>To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.</li>
                <li>To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.</li>
                <li>In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</li>
                <li>To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of our Services, or which, as determined by us, may harm or offend Company or users of our Services or expose them to liability.</li></ol></p>
                <p>Additionally, you agree not to:
                <ol type="A">
                    <li>Use our Services in any manner that could disable, overburden, damage, or impair our Services or interfere with any other party’s use of our Services, including their ability to engage in real time activities through our Services.</li>
                <li>Use any robot, spider, or other automatic device, process, or means to access our Services for any purpose, including monitoring or copying any of the material on our Services.</li>
                <li>Use any manual process to monitor or copy any of the material on our Services or for any other unauthorized purpose without our prior written consent.</li>
                <li>Use any device, software, or routine that interferes with the proper working of our Services.</li>
                <li>Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</li>
                <li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of our Services, the server on which our Services are stored, or any server, computer, or database connected to our Services.</li>
                <li>Attack our Services via a denial-of-service attack or a distributed denial-of-service attack.</li>
                <li>Otherwise attempt to interfere with the proper working of our Services.</li></ol></p>

                <h3>7. Users of the Services</h3>
                <p>Our Services are intended only for access and use by individuals at least thirteen (13) years old. By accessing or using any of our Services, you warrant and represent that you are at least thirteen (13) years of age and have the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of our Terms.</p>
                <h3>8. Intellectual Property</h3>
                <p>Our Services and original content (excluding Content provided by users), features, and functionality are and will remain the exclusive property of HAPPUS, LLC and its licensors. Our Services are protected by copyright and other laws. </p>

                <h3>9. Error Reporting and Feedback</h3>
                <p>You may contact us directly at support@happus.co with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Services (“<b>Feedback</b>”). </p>
                <p>You acknowledge and agree that: <ol type="A"><li>you shall not retain, acquire, or assert any intellectual property right or other right, title, or interest in or to the Feedback;</li> <li>Company may have development ideas similar to the Feedback;</li> <li>Feedback does not contain confidential or proprietary information from you or any third party;</li> <li>Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited, and perpetual right to use (including copy, modify, create derivative works, publish, distribute, and commercialize) Feedback in any manner and for any purpose.</li></ol></p>
                <h3>10. Links To Other Websites</h3>
                <p>Our Services may contain links to third party web sites or services that are not owned or controlled by HAPPUS, LLC.</p>
                <p>HAPPUS, LLC has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</p>
                <p>YOU ACKNOWLEDGE AND AGREE THAT HAPPUS, LLC SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.
                WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.</p>
                <h3>11. Disclaimer Of Warranty</h3>
                <p>THESE SERVICES ARE PROVIDED BY THE COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.</p>
                <p>NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
                <p>COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                <h3>12. Limitation Of Liability</h3>
                <p>EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>
                <h3>13. Termination</h3>
                <p>We may terminate or suspend your account and bar access to our Services immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>
                <p>You have the right to deactivate your account at any time by contacting us at support@happus.co.</p>
                <p>All provisions of the Terms that, by their nature, should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity provisions, and limitations of liability.</p>
                <h3>14. Changes To Services</h3>
                <p>We reserve the right to withdraw or amend our Services in our sole discretion without notice. We will not be liable if, for any reason, all or any part of our Services are unavailable at any time or for any period.</p>
                <h3>15. Amendments To Terms</h3>
                <p>We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.</p>
                <p>Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes.</p>
                <p>By continuing to access or use our Services after any revisions become effective, you agree to be bound by the revised terms.</p>
                <h3>16. Waiver And Severability</h3>
                <p>No waiver by Company of any term or condition set forth in Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to assert a right or provision under Terms shall not constitute a waiver of such right or provision.</p>
                <p>If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.</p>
                <h3>17. Acknowledgement</h3>
                <p>BY USING OUR SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</p>
                <h3>18. Contact Us</h3>
                <p>If you have any questions or concerns regarding these Terms, please contact us at <a href="mailto:support@happus.co">support@happus.co</a></p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
