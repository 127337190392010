import React from "react";
import Header from "components/headers/light.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import ResponsiveVideo from "components/embeds/VideoEmbed.js";

import Features from "components/features/VerticalWithAlternateImageAndText.js";

import TabGrid from "components/cards/TabCardGrid.js";
import Footer from "components/footers/MiniCenteredFooter";

import { Text } from "components/misc/Headings";

import VisusLogo from "images/visus-logo.png";

import VisusAppStoreIcon from "images/visus-appstore-icon.png";
import VisusLogoLong from "images/visus-logo-long.png";
import VisusLogoLongBrand from "images/visus-logo-long-brand.png";

import VisusScreenShot1 from "images/visus-screenshot-1.png";
import VisusScreenShot2 from "images/visus-screenshot-2.png";
import VisusScreenShot3 from "images/visus-screenshot-3.png";
import VisusScreenShot4 from "images/visus-screenshot-4.png";

import VisusPromoVideo from "videos/visus-promo-video.mp4";

export default (  features = null,
  ) => {
  
  
  return (
    <AnimationRevealPage>
      <Header/>
      <Features 
      app = "Visus"
      hasLinks = {true}
      instagramLink = {null}
      twitterLink = "https://twitter.com/visus_app"
      tiktokLink = {null}
      cards = {[
       {
         imageSrc:
           VisusLogo,
         title: "What is it?",
         description:
         <Text>
           <p>
           Visus is a great tool for making vision boards to help you reach your goals. Picture your dream life, stay motivated, and turn your dreams into reality. Start living the life you want today!
           </p>
         </Text>,
       },
   
       {
         imageSrc: null,       
         title: "Key Features",
         description:
         <Text>
           <ul>
           <li>Create beautiful vision board and collages using different layouts and styles</li>
           <li>Track tasks that help you achieve specific goals</li>
           <li>Get powerful affirmations to stay motivated for each goal</li>
           <li>Use widgets to always be inspired by your dreams </li>
           <li>Easy Customization: Style your vision boards with personal touch</li>
           <li>10+ languages supported</li>
         </ul>
         </Text>,
       }, 
   
       {
         imageSrc: null,
         title: "Who is it for?",
         description:
         <Text>
         <p>
         Our vision board maker app is for anyone who wants to set clear goals, stay focused, and make their dreams come true. Whether you're a student, a professional, an entrepreneur, or someone looking for personal growth, our app helps you visualize your aspirations easily.
</p><p>
It's designed for those who believe in the power of visualization to achieve success and fulfillment in life. Whether you're starting your journey or already making progress, our app is here to support you all the way.
         </p>
         
       </Text>,
       },
       
       {
         imageSrc: null,
         title: "Pricing",
         description:
         <Text>
         <p>
         Visus is free to download and to use. You can upgrade to Visus Premium for $3.99/week, $6.99/month, $19.99/quarter or $49.99 for lifetime to gain access to some additional features
         </p>
       </Text>,
       }
      ]}
    />

            <TabGrid

heading = "Media Assets"
tabs = {{
  Assets: [
    {
      imageSrc: VisusAppStoreIcon,
      title: "App Store Icon",
      content: "Logos & Icons",
    },
    {
      imageSrc: VisusLogo,
      title: "Logo",
      content: "Logos & Icons",
    }, 
    {
      imageSrc: VisusLogoLongBrand,
      title: "Logo Long Alternative",
      content: "Logos & Icons",
    },   
    {
      imageSrc: VisusLogoLong,
      title: "Logo Long",
      content: "Logos & Icons",
    },
    {
      imageSrc: VisusScreenShot1,
      title: "Tasks",
      content: "Screenshots",
    },
    {
      imageSrc: VisusScreenShot2,
      title: "Affirmations",
      content: "Screenshots",
    }, 
    {
      imageSrc: VisusScreenShot3,
      title: "Vision Board",
      content: "Screenshots",
    },   
    {
      imageSrc: VisusScreenShot4,
      title: "Different Styles",
      content: "Screenshots",
    },
      
    
  ],
}}
       
      />

<ResponsiveVideo watchVideoYoutubeUrl={VisusPromoVideo}></ResponsiveVideo>

      <Footer />
    </AnimationRevealPage>
  );
}
