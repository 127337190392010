import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
// import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import { SectionHeading, Text } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;

export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <SectionHeading>{headingText}</SectionHeading>
          </HeadingRow>
          <Text>
          {/* <h2> Privacy Policy </h2> */}
                        <p>Effective Date: February 1, 2023</p>
                        <h3>1. Introduction</h3>
                        <p>Our Privacy Policy is designed to assist you in understanding what information we collect, why we collect it, how we use it, and your rights and choices regarding your data.</p>
                        <p>HAPPUS, LLC, (“<b>us</b>”, “<b>we</b>”, or “<b>our</b>”) operates <a href="https://www.happus.co">https://www.happus.co</a> website and mobile applications. We will refer to our website and mobile applications as "<b>Services</b>" throughout this document.</p>

                        <p>We use your data to provide and improve our Services. By using our Services, you agree to the collection and use of information in accordance with this policy. We will not use or share your information with anyone except as described in this Privacy Policy.</p>

                        <p>The terms used in this Privacy Policy have the same meanings as in our Terms of Service, which are accessible at the Happus website <a href="https://www.happus.co">https://www.happus.co</a> unless otherwise defined in this Privacy Policy.</p>
                        <h3>2. Types of Data Collected</h3>
                        <h4>Personal Data</h4>
                        <p>We may ask you to provide us with personally identifiable information that can be used to contact or identify you while using our Services ("<b>Personal Data</b>"). Personal identifiable information may include, but is not limited to, the following:</p>
                        <p>
                            <ol type="A">
                            <li>Email address</li>
                        <li>First name</li>
                        <li>Photo</li>
                        <li>Social Media Identifiers</li>
                        <li>Usage Data</li>
                        <li>Tracking Cookies Data</li>
                        </ol>
                        </p>

                        <p>You may opt out of receiving marketing or promotional communications from us at any time by following the unsubscribe link.</p>
                        <h4>Usage Data</h4>
                        <p>We may also collect information sent by your browser whenever you visit our Services or access our Services via a mobile device ("<b>Usage Data</b>"). Usage Data may include information about the various actions you take while using our Services, such as your IP address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data. This data is used to help us better understand how to improve our Services.</p>
                        <h4>Tracking Cookies Data</h4>
                        <p>To track activity on our Services, we use cookies and other tracking technologies.</p>
                        <p>Cookies are small text files that may contain an anonymous unique identifier and are sent to your browser from a website and stored on your device. Other tracking technologies, such as beacons, tags, and scripts, may also be used to collect and track information, as well as to improve and analyze our Services.</p>
                        <h3>3. Use of Data</h3>
                        <p>HAPPUS, LLC may use the collected data for following purposes:</p>
                        <p>
                            <ol type="A">
                            <li>to provide and maintain our Services;</li>
                        <li>to manage users’ accounts;</li>
                        <li>to provide customer support;</li>
                        <li>to analyze usage data so that we can improve our Services;</li>
                        <li>to detect, prevent and address technical issues;</li>
                        <li>to contact users and manage users’ requests;</li></ol>
                        </p> <p>and for any other purpose with your consent.
                        </p>

                        <h3>4. Retention of Data</h3>
                        <p>We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>

                        <p>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Services, or we are legally obligated to retain this data for longer time periods.</p>
                        <h3>5. Transfer of Data</h3>
                        <p>Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>

                        <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer. We will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
                        <h3>6. Disclosure of Data</h3>
                        <p>We reserve the right to disclose personal information that we collect or that you provide:
                        <ol type="A"><li><b>Disclosure for Law Enforcement.</b> Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.</li>
                        <li><b>Business Transaction.</b> If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.</li>
                        <li><b>Third Party Service Providers.</b> Third party service providers we use to support our business have access to your Personal Data only as required to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</li></ol></p>
                        <h3>7. Security of Data</h3>
                        <p>The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

                        <h3 id="deletion-of-data">8. Deletion of Data</h3>
                        <p>We intend to take reasonable steps to provide you with the ability to correct, amend, delete, or limit the use of your Personal Data.</p>

                        <p>If you want to know what Personal Data we have on file for you and have it removed from our systems, please contact us at <a href="support@happus.co">support@happus.co</a>. You can also remove it yourself by pressing the appropriate button in the app's Profile section.</p> 

                        <h3>9. Service Providers</h3>
                        <p>We may employ third party companies and individuals to facilitate our Services (“<b>Service Providers</b>”), provide Services on our behalf, or assist us in analyzing how our Services are used.</p>

                        <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
                        <h3>10. Links to Other Sites</h3>
                        <p>Our Services may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>

                        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                        <h3>11. Children's Privacy</h3>
                        <p>Our Services are not intended for use by children under the age of 13 (“<b>Children</b>”).</p>

                        <p>We do not knowingly collect personally identifiable information from children under 13 years of age. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do the necessary actions.</p>
                        <h3>12. Changes to This Privacy Policy</h3>
                        <p>We may update our Privacy Policy from time to time. Any changes will be communicated to you by posting the new Privacy Policy on this page.</p>

                        <p>If you continue to use our Services after changes are in effect, you agree to the revised Privacy Policy. If the changes are material, we will provide a more prominent notice or seek your consent to the new policy.</p>
                        <h3>13. Contact Us</h3>
                        <p>If you have any questions or concerns regarding this Privacy Policy, please contact us by email at:  <a href="mailto:support@happus.co">support@happus.co</a></p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
