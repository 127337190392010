import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import "style.css"


import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from './pages/Home.js';
import Privacy from './pages/PrivacyPolicy.js';
import Terms from './pages/TermsOfService.js';
import PressKitHappus from './pages/PressKitHappus.js';
import PressKitTimus from './pages/PressKitTimus.js';
import PressKitVisus from './pages/PressKitVisus.js';
import Visus from './pages/Visus.js';
import NotFound from "NotFound.js";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


  return (
    <>
      <GlobalStyles />
      <Router>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route path="/privacy-policy" element={<Privacy/>}/>
        <Route path="/terms-of-service" element={<Terms/>}/>
        <Route path="/press-kit/happus" element={<PressKitHappus/>}/>
        <Route path="/press-kit/timus" element={<PressKitTimus/>}/>
        <Route path="/press-kit/visus" element={<PressKitVisus/>}/>
        <Route path="/visus" element={<Visus/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
       
      </Router>
    </>
  );
}

