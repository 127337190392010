import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import "style.css"
import Modal from "react-modal";
import TagManager from 'react-gtm-module'

Modal.setAppElement("#root");

const tagManagerArgs = {
    gtmId: 'GTM-5RZJ8PL'
    }
    
    TagManager.initialize(tagManagerArgs)

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);