import React from "react";
import Header from "components/headers/light.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import ResponsiveVideo from "components/embeds/VideoEmbed.js";

import Features from "components/features/VerticalWithAlternateImageAndText.js";

import TabGrid from "components/cards/TabCardGrid.js";
import Footer from "components/footers/MiniCenteredFooter";

import { Text } from "components/misc/Headings";

import TimusLogo from "images/timus-logo.png";

import TimusAppStoreIcon from "images/timus-appstore-icon.png";
import TimusLogoLong from "images/timus-logo-long.png";
import TimusLogoLongBrand from "images/timus-logo-long-brand.png";

import TimusScreenShot1 from "images/timus-screenshot-1.png";
import TimusScreenShot2 from "images/timus-screenshot-2.png";
import TimusScreenShot3 from "images/timus-screenshot-3.png";
import TimusScreenShot4 from "images/timus-screenshot-4.png";

import TimusPromoVideo from "videos/timus-promo-video.mp4";

export default (  features = null,
  ) => {
  
  return (
    <AnimationRevealPage>
      <Header/>
      <Features 
      app = "Timus"
      hasLinks = {true}
      instagramLink = {null}
      twitterLink = "https://twitter.com/timus_app"
      tiktokLink = {null}
      cards = {[
       {
         imageSrc:
           TimusLogo,
         title: "What is it?",
         description:
         <Text>
           <p>
           Timus is an app that allows you to run multiple timers (yes, even interval ones!) and customize them easily. Sport, productivity, cooking timers — all together in one app and can be run at once.
           </p>
         </Text>,
       },
   
       {
         imageSrc: null,       
         title: "Key Features",
         description:
         <Text>
           <ul>
           <li>Multiple Timers: Run them all at once</li>
           <li>Multi-step or Interval Timers: Create any number of steps or intervals</li>
           <li>Templates for any Case: Tabata, Pomodoro, Rare-steaks…whatever you need</li>
           <li>Quick Timers: One click to run a timer</li>
           <li>Easy Customization: Style the timer with personal touch</li>
           <li>10+ languages supported</li>
         </ul>
         </Text>,
       },
       {
        imageSrc: null,       
        title: "iOS Features",
        description:
        <Text>
          <ul>
          <li>Live Activities</li>
          <li>Dynamic Island</li>
          <li>Widgets for Home Screen</li>
          <li>Quick Actions</li>
          <li>Accessibility (Dark Mode and Dynamic Font)</li>
        </ul>
        </Text>,
      },
  
   
       {
         imageSrc: null,
         title: "Who is it for?",
         description:
         <Text>
         <p>
         Honestly, for anyone! Whether you're a busy professional, a cooking aficionado, a fitness enthusiast, a student on the grind, or anyone who values their time, our app is all about making life easier for you.
         </p>
         <p>
                          <ol >
                           <li>
                          <b>Professionals</b>  
                           <ul>
                              <li>Stay on top of meetings, conference calls, and project deadlines</li>
                              <li>Efficiently manage time-sensitive tasks and boost productivity</li>
                            </ul>
                          </li> 
                            <li> <b>Cooking Aficionado</b>
                              <ul>
                              <li>Perfectly time your meal courses with multiple cooking timers</li>
                              <li>Juggle various dishes and cooking processes with minimum effort</li>
                              </ul>                           
                            </li>
                            <li> <b>Fitness Enthusiasts</b>
                              <ul>
                              <li>Optimize your workouts with dedicated timers for sets, reps, and rest intervals</li>
                              <li>Achieve your fitness goals with precision timing</li>
                              </ul>                             
                            </li>
                            <li> <b>Students</b>
                              <ul>
                              <li>Maintain a structured study routine with timers for study sessions and breaks</li>
                              <li>Improve time management for assignments and exams</li>
                              </ul>                             
                            </li>
                            <li><b>Parents</b>
                              <ul>
                                <li>Manage household tasks, chores, and routines with ease</li>
                              <li>Keep track of multiple schedules for your family members</li>
                              </ul>                           
                            </li>
                          </ol>
                        </p>
       </Text>,
       },
       
       {
         imageSrc: null,
         title: "Pricing",
         description:
         <Text>
         <p>
         Timus is free to download and to use. You can upgrade to Timus Premium for $2.99/month, $5.99/quarter, $9.99/year or $29.99 for lifetime to gain access to some additional features
         </p>
       </Text>,
       }
      ]}
    />

            <TabGrid

heading = "Media Assets"
tabs = {{
  Assets: [
    {
      imageSrc: TimusAppStoreIcon,
      title: "App Store Icon",
      content: "Logos & Icons",
    },
    {
      imageSrc: TimusLogo,
      title: "Logo",
      content: "Logos & Icons",
    }, 
    {
      imageSrc: TimusLogoLongBrand,
      title: "Logo Long Alternative",
      content: "Logos & Icons",
    },   
    {
      imageSrc: TimusLogoLong,
      title: "Logo Long",
      content: "Logos & Icons",
    },
    {
      imageSrc: TimusScreenShot1,
      title: "Multiple Timers",
      content: "Screenshots",
    },
    {
      imageSrc: TimusScreenShot2,
      title: "Interval & Multi Step",
      content: "Screenshots",
    }, 
    {
      imageSrc: TimusScreenShot3,
      title: "Templates",
      content: "Screenshots",
    },   
    {
      imageSrc: TimusScreenShot4,
      title: "Create Timer",
      content: "Screenshots",
    },
      
    
  ],
}}     
      />

<ResponsiveVideo watchVideoYoutubeUrl={TimusPromoVideo}></ResponsiveVideo>

      <Footer />
    </AnimationRevealPage>
  );
}
