import React from "react";
import Header from "components/headers/light.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import ResponsiveVideo from "components/embeds/VideoEmbed.js";

import Features from "components/features/VerticalWithAlternateImageAndText.js";

import TabGrid from "components/cards/TabCardGrid.js";
import Footer from "components/footers/MiniCenteredFooter";

import { Text } from "components/misc/Headings";

import HappusLogo from "images/happus-logo.png";

import HappusAppStoreIcon from "images/happus-appstore-icon.png";
import HappusLogoLong from "images/happus-logo-long.png";
import HappusLogoLongBrand from "images/happus-logo-long-brand.png";

import HappusScreenShot1 from "images/happus-screenshot-1.png";
import HappusScreenShot2 from "images/happus-screenshot-2.png";
import HappusScreenShot3 from "images/happus-screenshot-3.png";
import HappusScreenShot4 from "images/happus-screenshot-4.png";

import HappusPromoVideo from "videos/happus-promo-video.mp4";


export default (  features = null,
  ) => {
  
  return (
    <AnimationRevealPage>
      <Header/>
      <Features 
      app = "Happus"
      hasLinks = {true}
      instagramLink = "https://www.instagram.com/happus.co/"
      twitterLink = "https://twitter.com/happus_app"
      tiktokLink = "https://www.tiktok.com/@happusapp"
      cards = {[
       {
         imageSrc:
           HappusLogo,
         title: "What is it?",
         description:
         <Text>
           <p>
           Happus is an app for building happy and healthy relationship. It offers a science-based approach for improving couples relationships
           </p>
         </Text>,
       },
   
       {
         imageSrc: null,       
         title: "Key Features",
         description:
         <Text>
           <ul>
           <li>Count the days since you started dating</li>
           <li>Track your relationship satisfaction and get tips to improve it</li>
           <li>Complete science-backed check-ins to spot any issues</li>
           <li>Get detailed quality time and date ideas</li>
           <li>Get research-based discussion topics and take quizzes</li>
           <li>Play relationship versions of games Bingo and Would You Rather</li>
           <li>Track relationship health with dashboards and gain insights</li>
         </ul>
         </Text>,
       },
   
       {
         imageSrc: null,
         title: "Who is it for?",
         description:
         <Text>
         <p>
         Happus is for couples who want to make their relationship a priority. Even when it was a busy day at work or even a busy month and you don’t really have any energy to come up with any discussion topics or quality time ideas.
         </p>
         <p>
         Whether you're a new couple, in a long-term relationship, or married, Happus will make your relationships deeper and more exciting.
       </p>
       </Text>,
       },
       {
         imageSrc: null,
         title: "Testimonials",
         description:
         <Text>
         <p>
         «Definitely recommend! What a neat app for couples! My husband and I love the fact that it has different creative activities for us to do together and it gives us an opportunity to look at our relationship in a different way. Every couple needs to download Happus!»
         </p>
         <p>
         Brittany, USA
       </p>
       </Text>,
       },
       {
         imageSrc: null,
         title: "Pricing",
         description:
         <Text>
         <p>
         Happus is free to download. You can upgrade to Happus Premium for $6.99/month, $44.99/year to gain access to some content
         </p>
       </Text>,
       }
      ]}
    />

            <TabGrid

heading = "Media Assets"
tabs = {{
  Assets: [
    {
      imageSrc: HappusAppStoreIcon,
      title: "App Store Icon",
      content: "Logos & Icons",
    },
    {
      imageSrc: HappusLogo,
      title: "Logo",
      content: "Logos & Icons",
    }, 
    {
      imageSrc: HappusLogoLongBrand,
      title: "Logo Long Alternative",
      content: "Logos & Icons",
    },   
    {
      imageSrc: HappusLogoLong,
      title: "Logo Long",
      content: "Logos & Icons",
    },
    {
      imageSrc: HappusScreenShot1,
      title: "Home Screen",
      content: "Screenshots",
    },
    {
      imageSrc: HappusScreenShot2,
      title: "Discussions",
      content: "Screenshots",
    }, 
    {
      imageSrc: HappusScreenShot3,
      title: "Date Ideas",
      content: "Screenshots",
    },   
    {
      imageSrc: HappusScreenShot4,
      title: "Quizzes",
      content: "Screenshots",
    },
      
    
  ],
}}      
      />

<ResponsiveVideo watchVideoYoutubeUrl={HappusPromoVideo}></ResponsiveVideo>

      <Footer />
    </AnimationRevealPage>
  );
}
