import React from "react";
import Header from "components/headers/light.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import MainFeature1 from "components/features/TwoColWithButtonAndImage";

// import Features from "components/features/ThreeColSimple.js";

// import Features2 from "components/features/VerticalWithImageAndText.js";

import Footer from "components/footers/MiniCenteredFooter";

// import { Text } from "components/misc/Headings.js";

import VisusFrame from "images/visus-frame.png";
// import DownloadIcon from "images/download-icon.svg";
// import StarIcon from "images/star-icon.svg";
// import HeartIcon from "images/heart-icon.svg";


export default (  features = null,
  ) => {
  
  
  return (
    <AnimationRevealPage>
      <Header/>
      
      <MainFeature1
        subheading="Visus"
        heading="Visualize and achieve your goals"
        description="Visus is a powerful vision board maker that helps you achieve your dreams. Visualize your ideal life, stay motivated, and make your goals a reality. Start living the life you've always imagined today"
        buttonRounded={false}
        secondaryButtonUrl="https://apps.apple.com/app/id6473835640"
        secondaryButtonId = "visus_appstore_visusapp_button"
        primaryButtonText=""
        primaryButtonUrl=""
        imageSrc={VisusFrame}
        imageCss={true}
        textOnLeft={true}
      />

    {/* <Features
        subheading={false}
        heading="About Visus"
        linkText = ""
        cards={[
          {
            imageSrc: DownloadIcon,
            title: "30K+ downloads",
            description: "Thousands of users worldwide download Visus yearly"
            
            
          },
          {
            imageSrc: StarIcon,
            title: "4.8 stars",
            description: "The quality, detail, and positive impact of Visus is a priority"
          },
          {
            imageSrc: HeartIcon,
            title: "400+ ratings",
            description: "Every rating and review matters and is used to improve Visus"
          },
          
        ]
  
      }
      />

      <Features2
      app = "Visus"
      hasLinks = {false}
      instagramLink = {null}
      twitterLink = {null}
      tiktokLink = {null}
      cards = {[
       {
        
         imageSrc: null,       
         title: "Key Features",
         description:
         <Text>
           <ul>
           <li>Create beautiful vision board and collages using different layouts and styles</li>
           <li>Track tasks that help you achieve specific goals</li>
           <li>Get powerful affirmations to stay motivated for each goal</li>
           <li>Use widgets to always be inspired by your dreams </li>
           <li>Easy Customization: Style your vision boards with personal touch</li>
           <li>10+ languages supported</li>
         </ul>
         </Text>,
       }, 
   
       {
         imageSrc: null,
         title: "Who is it for?",
         description:
         <Text>
         <p>
         Our vision board maker app is for anyone who wants to set clear goals, stay focused, and make their dreams come true. Whether you're a student, a professional, an entrepreneur, or someone looking for personal growth, our app helps you visualize your aspirations easily.
</p><p>
It's designed for those who believe in the power of visualization to achieve success and fulfillment in life. Whether you're starting your journey or already making progress, our app is here to support you all the way.
         </p>
         
       </Text>,
       },
       
       
      ]}
    /> */}

            

      <Footer />
    </AnimationRevealPage>
  );
}
